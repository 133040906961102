<template>
    <div class="page" v-show="isDisplay">
        <CompContent :title="title + '审核'" :intercept="intercept">
            <!-- 头部搜索层 -->
            <template #operate>
                <Button v-if="detail && detail.status != 1" type="primary" @click="onSubmitAudit(1)">通过</Button>
                <Button v-if="detail && detail.status != 1 && detail.status != 2" type="error" @click="$refs.reject_modal.display()">驳回</Button>

                <Dropdown v-if="detail" transfer @on-click="onExport">
                    <Button type="info">
                        导出
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <template #list>
                        <DropdownMenu>
                            <DropdownItem name="photo">导出图片</DropdownItem>
                            <DropdownItem name="pdf">导出PDF</DropdownItem>
                        </DropdownMenu>
                    </template>
                </Dropdown>
                <Button v-if="id && ids && !isPublished" :disabled="ids.indexOf(id) === ids.length - 1" @click="onNext">下一篇 ({{ ids.indexOf(id) + 1 }}/{{ ids.length }})</Button>
            </template>
            <div ref="audit_base" class="base" v-if="detail">
                <div class="head">
                    <div class="source">广州市【社区随约服务网上驿站】</div>
                    <div class="title">工·作·周·报</div>
                    <div class="explain">( {{ detail.itemPeriod }} )</div>
                    <div class="push">主送单位：{{ detail.deliveryUnit }}</div>
                    <div class="date">{{ detail.createTime }}</div>
                </div>
                <div class="date-box">数据统计时间截止至 {{ detail.cutOffTime || detail.createTime }}</div>
                <div class="model-box" style="padding:10px 5px">
                    <div class="module-box">
                        <div class="item-box" v-for="(item, idx) in modules[0]" :key="idx">
                            <Poptip class="hint" v-if="item.h" word-wrap :content="item.h" trigger="hover">
                                <Icon class="icon" type="ios-help-circle-outline" />
                            </Poptip>
                            <div class="name">{{ item.t }}</div>
                            <div class="value" v-if="item.k">{{ detail[item.k] }}</div>
                            <div class="row-box" v-if="item.l === 'row'">
                                <div class="col" v-for="(v, i) in item.i" :key="i">
                                    <div class="item" v-for="(vi, ii) in v" :key="ii" :style="{ color: vi.c }">
                                        <p>{{ vi.t }}:</p>
                                        <p class="val" :contenteditable="!isPublished" @blur="onChangeDetailNumber($event, vi.n)" :style="vi.s">{{ vi.render ? vi.render(detail[vi.n]) : detail[vi.n] || getEmptyHint(vi) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="module-box">
                        <div class="item-box" v-for="(item, idx) in modules[1]" :key="idx">
                            <Poptip class="hint" v-if="item.h" word-wrap :content="item.h" trigger="hover">
                                <Icon class="icon" type="ios-help-circle-outline" />
                            </Poptip>
                            <div class="name">{{ item.t }}</div>
                            <div class="value" v-if="item.k">{{ detail[item.k] }}</div>
                            <div class="row-box" v-if="item.l === 'row'">
                                <div class="col" v-for="(v, i) in item.i" :key="i">
                                    <div class="item" v-for="(vi, ii) in v" :key="ii" :style="{ color: vi.c }">
                                        <p>{{ vi.t }}:</p>
                                        <p class="val" :contenteditable="!isPublished" @blur="onChangeDetailNumber($event, vi.n)" :style="vi.s">{{ vi.render ? vi.render(detail[vi.n]) : detail[vi.n] || getEmptyHint(vi) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="increase-box" v-else>
                                <div class="item" v-for="(v, i) in item.i" :key="i">
                                    <p>{{ v.t }}:</p>
                                    <p class="val" :contenteditable="!isPublished" @blur="onChangeDetailNumber($event, v.n)" :style="v.s">{{ v.render ? v.render(detail[v.n]) : detail[v.n] || getEmptyHint(v) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 街镇周报数据 -->
                <StreetModel v-if="type === 'street'" :value="detail" :expand="isExpand"></StreetModel>
                <!-- 区周报数据 -->
                <ZoneModel v-else-if="type === 'zone'" version="v2" :value="detail" :expand="isExpand"></ZoneModel>
                <!-- 社区周边数据 -->
                <CommunityModel v-else :value="detail" :expand="isExpand"></CommunityModel>
                <!-- 总结建议 -->
                <div class="remark-box">
                    <div class="item-box" v-if="!isExpand || detail.summarize">
                        <div class="title">总结</div>
                        <Input Input :value="detail.summarize" :rows="8" :maxlength="1000" type="textarea" placeholder="填写整体情况" @on-blur="onChangeDetail($event, 'summarize')" />
                    </div>
                    <div class="item-box" v-if="!isExpand || detail.summarize">
                        <div class="title">建议</div>
                        <Input Input :value="detail.suggest" :rows="8" :maxlength="1000" type="textarea" placeholder="填写对接下来工作的建议" @on-blur="onChangeDetail($event, 'suggest')" />
                    </div>
                </div>
                <!-- 审核信息 -->
                <div class="user-info" v-if="detail.status == 1">
                    <div class="key">
                        <div class="item">汇报单位:</div>
                        <div class="item">汇报人:</div>
                        <div class="item">联系方式:</div>
                    </div>
                    <div class="value">
                        <div class="item">{{ detail.reportingUnit || "-" }}</div>
                        <div class="item">{{ detail.userName || "-" }}</div>
                        <div class="item">{{ detail.account || "-" }}</div>
                    </div>
                </div>
            </div>
        </CompContent>

        <CompModal ref="reject_modal" title="驳回审核" height="auto">
            <div style="padding: 10px 30px 10px 20px">
                <ElemFormItem title="驳回原因" name="name">
                    <Input style="width:350px" type="textarea" v-model="reject_formdata.reason" :maxlength="255" rows="4" placeholder="请填写驳回原因"></Input>
                </ElemFormItem>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn" @click="evt.close">取消</div>
                    <div class="operate-btn highlight" @click="onSubmitAudit(2)">保存</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
// import jspdf from "jspdf"
import CompContent from "../customform/components/CompContent.vue"
import Route from "../communitymobilization/entity/Route"
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import ElemFormItem from "@/views/jointly/components/elem-form-item.vue"

import CommunityModel from "./childrens/CommunityModel.vue"
import StreetModel from "./childrens/StreetModel.vue"
import ZoneModel from "./childrens/ZoneModel.vue"

import Request from "../jointly/utils/request"
import html2canvas from "html2canvas"
import Type from "./utils/type"

export default {
    components: { CompContent, CompModal, ElemFormItem, CommunityModel, StreetModel, ZoneModel },

    props: {
        ids: {
            type: Array,
            default: () => [],
        },

        source: {
            type: String,
            required: false,
        },
    },

    data() {
        let type = new Type(this.source)

        return {
            title: type.getTitle(),
            // 周报类型
            type: type.get(),
            route: Route,
            // 驳回表单
            reject_formdata: {},
            // 详情
            detail: {},
            // ID
            id: "",
            // 是否已发布
            isPublished: false,
            // 显示
            isDisplay: false,
            // 展开表格
            isExpand: false,
            // 拦截事件
            intercept: {
                back: () => {
                    this.isDisplay = false
                    this.$emit("on-back")
                    return false
                },
            },

            modules: [
                [
                    {
                        t: (type.get() === "community" ? "社区" : "") + "注册访问用户数",
                        k: "statRegister",
                        l: "row",
                        h: "登录进入过某个社区随约频道的用户，即为该社区（访问）用户，一个手机号为一个用户",
                        i: [
                            [
                                {
                                    t: "本期",
                                    n: "addRegister",
                                    s: {
                                        color: "green",
                                    },
                                    render: v => (v > 0 ? "+" + v : v),
                                },
                            ],
                            [
                                {
                                    t: "同比",
                                    c: "green",
                                    n: "momRegister",
                                },
                                {
                                    t: "环比",
                                    c: "red",
                                    n: "yoyRegister",
                                },
                            ],
                        ],
                    },
                    {
                        t: (type.get() === "community" ? "社区" : "") + "信息发布总数",
                        k: "statOrgPublishNum",
                        l: "row",
                        h: "所有服务主体或机构发布到该社区的资讯活动等内容总数",
                        i: [
                            [
                                {
                                    t: "本期",
                                    n: "addOrgPublishNum",
                                    s: {
                                        color: "green",
                                    },
                                    render: v => (v > 0 ? "+" + v : v),
                                },
                            ],
                            [
                                {
                                    t: "同比",
                                    c: "green",
                                    n: "momOrgPublishNum",
                                },
                                {
                                    t: "环比",
                                    c: "red",
                                    n: "yoyOrgPublishNum",
                                },
                            ],
                        ],
                    },
                    {
                        t: "接受服务人次",
                        k: "statViewNum",
                        l: "row",
                        h: "社区下信息的阅读总次数",
                        i: [
                            [
                                {
                                    t: "本期",
                                    n: "addViewNum",
                                    s: {
                                        color: "green",
                                    },
                                    render: v => (v > 0 ? "+" + v : v),
                                },
                            ],
                            [
                                {
                                    t: "同比",
                                    c: "green",
                                    n: "momViewNum",
                                },
                                {
                                    t: "环比",
                                    c: "red",
                                    n: "yoyViewNum",
                                },
                            ],
                        ],
                    },
                ],
                [
                    {
                        t: (type.get() === "community" ? "社区" : "") + "注册用户数",
                        k: "userRegister",
                        l: "row",
                        h: "社区居民数据库中登记了手机号且登录过随约的居民，或者自主入驻网上家园并通过审核的居民，同一个人有多套房算多个注册用户",
                        i: [
                            [
                                {
                                    t: "本期",
                                    n: "addUserRegister",
                                    s: {
                                        color: "green",
                                    },
                                    render: v => (v > 0 ? "+" + v : v),
                                },
                            ],
                            [
                                {
                                    t: "同比",
                                    c: "green",
                                    n: "momUserRegister",
                                },
                                {
                                    t: "环比",
                                    c: "red",
                                    n: "yoyUserRegister",
                                },
                            ],
                        ],
                    },
                    {
                        t: "参与活动人次",
                        k: "statJoinNum",
                        l: "row",
                        h: "社区下参与活动报名、问卷采集的总次数",
                        i: [
                            [
                                {
                                    t: "本期",
                                    n: "addJoinNum",
                                    s: {
                                        color: "green",
                                    },
                                    render: v => (v > 0 ? "+" + v : v),
                                },
                            ],
                            [
                                {
                                    t: "同比",
                                    c: "green",
                                    n: "momJoinNum",
                                },
                                {
                                    t: "环比",
                                    c: "red",
                                    n: "yoyJoinNum",
                                },
                            ],
                        ],
                    },
                    {
                        t: "村居委信息发布数",
                        k: "statPublishNum",
                        l: "row",
                        h: "村居委自己发布的通知活动等内容总数",
                        i: [
                            [
                                {
                                    t: "本期",
                                    n: "addPublishNum",
                                    s: {
                                        color: "green",
                                    },
                                    render: v => (v > 0 ? "+" + v : v),
                                },
                            ],
                            [
                                {
                                    t: "同比",
                                    c: "green",
                                    n: "momPublishNum",
                                },
                                {
                                    t: "环比",
                                    c: "red",
                                    n: "yoyPublishNum",
                                },
                            ],
                        ],
                    },
                    {
                        t: "引入服务主体数",
                        k: "serviceAmount",
                        l: "row",
                        h: "辖区单位、商家、机构等基本信息上传到平台的总数",
                        i: [
                            [
                                {
                                    t: "本期",
                                    n: "addServiceAmount",
                                    s: {
                                        color: "green",
                                    },
                                    render: v => (v > 0 ? "+" + v : v),
                                },
                            ],
                            [
                                {
                                    t: "同比",
                                    c: "green",
                                    n: "momServiceAmount",
                                },
                                {
                                    t: "环比",
                                    c: "red",
                                    n: "yoyServiceAmount",
                                },
                            ],
                        ],
                    },
                    {
                        t: "商户服务数",
                        h: "在随约入驻成为商家，提供交易服务的商家总数",
                        i: [
                            {
                                t: "有交易",
                                n: "shopTradesCount",
                            },
                            {
                                t: "全部商户",
                                n: "shopCount",
                            },
                        ],
                    },
                    ...(type.get() === "community"
                        ? [
                              {
                                  t: "栏目情况",
                                  h: "空白指栏目下没有发布任何内容信息；居民提醒更新指通过居民点击提醒内容更新的栏目；全部指社区频道下开通的栏目总数",
                                  i: [
                                      {
                                          t: "空白",
                                          n: "blankColumnNum",
                                          s: {
                                              color: "red",
                                          },
                                      },
                                      {
                                          t: "居民提示更新",
                                          n: "feedbackCount",
                                      },
                                      {
                                          t: "全部",
                                          n: "columnNum",
                                          z: false,
                                      },
                                  ],
                              },
                          ]
                        : []),
                ],
            ],
        }
    },

    watch: {
        id(v) {
            this.getDateil(v)
        },
    },

    methods: {
        load(id, isPublished) {
            // 清空详情
            this.detail = null
            this.isPublished = isPublished
            this.isDisplay = true

            if (isPublished) {
                return this.getDateilByOrgCode(id)
            }

            if (id === this.id) {
                this.getDateil(id)
            } else this.id = id
        },

        getDateilByOrgCode(id) {
            Request.get("/gateway/api/symanage/pc/report/getReportDetailByOrgCode", {
                itemId: id,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(this.process.bind(this))
        },

        getDateil(id) {
            Request.get("/gateway/api/symanage/pc/report/getReportFormDetail", {
                detailId: id,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(this.process.bind(this))
        },

        process(d) {
            if (d.firstColumnList?.length > 0) {
                d.firstColumnList = d.firstColumnList.map(item => ({
                    name: item,
                }))
            }

            if (d.reportDetail) {
                d = Object.assign({}, d, d.reportDetail)
            }

            // 周报日期大于 2023-09-15 则为新版周报
            this.isNew = new Date((d.cutOffTime || d.createTime || "").replace(/[年月]/g, "-").replace(/日/, "")).getTime() >= new Date("2023-09-15 00:00:00").getTime()

            this.detail = d
        },

        /**
         * 提交审核
         */
        onSubmitAudit(status) {
            var msg = ""

            if (status === 2) {
                if (!this.reject_formdata.reason?.trim()) {
                    return this.$Message.error("请填写驳回原因")
                }
                // 驳回原因
                msg = this.reject_formdata.reason
                // 清空驳回原因
                this.$set(this.reject_formdata, "reason", "")
                // 关闭驳回弹窗
                this.$refs.reject_modal.close()
            }

            Request.post("/gateway/api/symanage/pc/report/weeklyReportReview", {
                detailId: this.id,
                status: status,
                remark: msg,
            }).then(() => {
                this.$Message.success(status === 1 ? "审核成功" : "驳回成功")
                // 刷新详情
                setTimeout(() => {
                    this.getDateil(this.id)
                }, 1000)
                // 更新列表状态值
                this.$emit("on-change", {
                    tag: "Audit",
                    value: {
                        id: this.id,
                        remark: msg,
                        status,
                    },
                })
            })
        },

        onChangeDetailNumber(evt, key) {
            const text = evt.target.innerText
            const value = text === "-" ? 0 : text

            this.onChangeDetail(value, key)
        },

        onChangeDetail(evt, key) {
            const value = typeof evt === "object" ? evt?.target?.value || "" : evt

            // 相同值不提交更新
            if (this.detail[key] == value) return

            Request.post(
                "/gateway/api/symanage/pc/report/updateReportDetail",
                {
                    detailId: this.id,
                    attribute: key,
                    attributeValue: value,
                },
                {
                    success: "修改成功",
                }
            ).then(() => {
                // 更新详情值
                this.$set(this.detail, key, value)
            })
        },

        // 下一篇
        onNext() {
            let idx = this.ids.indexOf(this.id)

            if (idx === this.ids.length - 1) {
                return this.$Message.warning("已经是当前页最后一篇了")
            }

            this.load(this.ids[idx + 1])
        },

        onExportPDF() {
            // 展开表格
            this.isExpand = true
            // 加载提示
            this.$Message.loading("正在导出...")
            // 下一个片段
            this.$nextTick(async () => {
                // 生成图片
                const canvas = await html2canvas(this.$refs.audit_base, { useCORS: true })
                var iframe = document.createElement("iframe")
                iframe.setAttribute("class", "print-iframe")
                iframe.setAttribute("style", "position:absolute;left:0;top:0;visibility:hidden;")
                document.body.appendChild(iframe)
                var doc = iframe.contentWindow.document

                // 样式
                const style = doc.createElement("style")
                style.innerHTML = `
                    @media print {
                        body {
                            margin: 0;
                            -webkit-print-color-adjust: exact;
                        }

                        .no-print {
                            display: none;
                        }
                    }

                    @page {
                        margin-bottom: 0mm;
                        margin-top: 0mm;
                    }
                `
                doc.body.appendChild(style)

                // 固定宽度 800 px，计算比例
                const ratio = 800 / canvas.width
                const img = doc.createElement("img")
                img.src = canvas.toDataURL("image/png")
                img.style.height = `${canvas.height * ratio}px`
                img.style.width = "800px"
                // 监听图片加载完成事件
                img.onload = () => {
                    // 触发打印
                    iframe.contentWindow.print()
                    if (navigator.userAgent.indexOf("MSIE") > 0) {
                        document.body.removeChild(iframe)
                    }
                    // 完成
                    this.complete()
                }

                doc.body.appendChild(img)
                doc.close()
                iframe.contentWindow.focus()
            })
        },

        onExport(type) {
            type === "photo" ? this.onExportPhoto() : this.onExportPDF()
        },

        onExportPhoto() {
            const detail = this.detail
            // 展开表格
            this.isExpand = true
            // 加载提示
            this.$Message.loading("正在导出...")
            // 下一个片段
            this.$nextTick(() => {
                // 生成图片
                html2canvas(this.$refs.audit_base, { useCORS: true })
                    .then(canvas => {
                        let src = canvas.toDataURL()
                        var image = new Image()

                        // 解决跨域 Canvas 污染问题
                        image.setAttribute("crossOrigin", "anonymous")
                        image.onload = () => {
                            canvas.width = image.width
                            canvas.height = image.height
                            var context = canvas.getContext("2d")
                            context.drawImage(image, 0, 0, image.width, image.height)
                            var url = canvas.toDataURL("image/png", 1.0)
                            var a = document.createElement("a")
                            var event = new MouseEvent("click")
                            a.download = `工作周报 (${detail.itemPeriod}) - ${detail.deliveryUnit}`
                            a.href = url
                            a.dispatchEvent(event)
                            // 完成
                            this.complete()
                        }
                        image.onerror = () => {
                            this.complete(true)
                        }
                        image.src = src
                    })
                    .catch(() => {
                        this.complete(true)
                    })
            })
        },

        complete(e) {
            // 销毁加载提示
            this.$Message.destroy()
            // 错误提示
            e && this.$Message.error("导出失败")
            // 收起表格
            this.isExpand = false
        },

        getEmptyHint(v) {
            if (this.isNew && v.z === false) {
                return "-"
            }

            return 0
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    background: #fff;

    .toolsbarRight {
        button {
            margin: 5px 10px 5px 0 !important;
        }
    }

    .base {
        width: 1400px;
        max-width: 90%;
        margin: 0 auto;
        min-width: 410px;
        padding-bottom: 40px;

        .head {
            width: 100%;
            border-bottom: 2px solid red;
            padding: 10px;
            box-sizing: border-box;
            line-height: 1;

            .source {
                font-size: 18px;
                margin-top: 20px;
                margin-bottom: 20px;
                text-align: center;
                color: red;
            }

            .title {
                font-size: 28px;
                margin-bottom: 20px;
                text-align: center;
                color: red;
            }

            .explain {
                font-size: 14px;
                margin-bottom: 20px;
                text-align: center;
                color: red;
                letter-spacing: 1px;
            }

            .push {
                font-size: 14px;
                color: #666;
                margin-bottom: 30px;
            }

            .date {
                text-align: right;
                font-size: 14px;
                color: #666;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .date-box {
            margin: 10px 10px 0 0;
            text-align: right;
            font-size: 14px;
            color: #666;
        }

        /deep/.model-box {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;

            &:first-child {
                margin-top: 0;
            }

            .b-title {
                font-size: 18px;
                color: #21c065;
                margin-bottom: 5px;
            }

            .title {
                font-size: 15px;
                color: #333;
            }

            .explain {
                margin: 2px 5px 0 5px;
                font-size: 12px;
                color: #999;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .hint {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    color: #888;

                    .icon {
                        font-size: 15px;
                    }

                    .name {
                        margin-left: 5px;
                    }
                }
            }

            > .table-box {
                margin-top: 8px;
            }

            .module-box {
                display: flex;
                flex-wrap: wrap;

                .item-box {
                    position: relative;
                    flex: 1;
                    padding: 10px;
                    margin: 5px;
                    box-sizing: border-box;
                    border: 1px solid #f2f2f2;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    min-width: 140px;

                    .hint {
                        position: absolute;
                        top: 3px;
                        right: 10px;
                        font-size: 20px;
                        color: #888;
                        cursor: pointer;
                    }

                    .name {
                        font-size: 14px;
                        color: #888;
                        margin-bottom: 10px;
                        text-align: center;
                    }

                    .value {
                        font-size: 28px;
                        color: #333;
                        margin-bottom: 10px;
                        text-align: center;
                    }

                    .row-box {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;

                        .col {
                            flex: 1;
                            max-width: 150px;
                            padding: 0 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            flex-shrink: 0;

                            .item {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 5px;
                                color: #333;
                                flex-shrink: 0;

                                p {
                                    font-size: 14px;
                                    margin-right: 5px;
                                    white-space: nowrap;
                                }

                                .val {
                                    font-size: 14px;
                                    padding: 0 5px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }

                    .increase-box {
                        width: 100%;

                        .item {
                            margin-top: 5px;
                            font-size: 13px;
                            color: #777;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .val {
                                font-size: 14px;
                                padding: 0 5px;
                                color: #333;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

        .remark-box {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;

            &:empty {
                display: none;
            }

            .item-box {
                flex: 1;
                min-width: 300px;
                padding: 0 10px;

                .title {
                    font-size: 14px;
                    color: #138f48;
                    margin-bottom: 5px;
                    margin-left: 10px;
                }

                .el-input {
                    width: 100%;
                }
            }
        }

        .user-info {
            border-top: 2px solid red;
            margin-top: 20px;
            padding: 20px 10px;
            display: flex;
            justify-content: flex-end;

            .item {
                margin-bottom: 10px;
                min-width: 60px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .key {
                padding-right: 10px;

                .item {
                    text-align: right;
                    color: #666;
                }
            }
        }
    }

    // /deep/.elem-table {
    //     max-height: calc(100vh - 150px) !important;
    // }
}
</style>
